





































































































































































import Vue from 'vue'
import { mapActions, mapState } from 'vuex'
import { FormMixin } from '@/components/Mixins'

const props = {
  apiCallInProgress: {
    type: Boolean,
    default: false
  },
  error: {
    type: Error,
    default: null
  },
  mapNames: {
    type: Array,
    default: () => []
  },
  active: {
    type: Boolean,
    default: false
  }
}

export default Vue.extend({
  name: 'AddOfflineMapLayer',
  props,
  mixins: [FormMixin],
  data() {
    return {
      isFallback: false as boolean,
      maxZoom: 18 as number,
      layerName: null as string,
      mapAttribution: null as string,
      file: null as File,
      isSelecting: false as boolean,
      formValid: false as boolean,
      focusedInput: 0 as number
    }
  },
  async beforeMount() {
    this.$store.commit('maps/SET_ERROR', null)
    this.$store.commit('maps/SET_STATUS', null)
  },
  mounted() {
    document.addEventListener('keyup', this.onEnterKeyup)
    this.focusOnInput(this.focusedInput)
  },
  beforeDestroy() {
    document.removeEventListener('keyup', this.onEnterKeyup)
  },
  computed: {},
  methods: {
    ...mapActions('maps', ['setOfflineMApUploadProgress']),
    focusOnInput(index) {
      setTimeout(() => {
        this.$refs[`input-${index}`].focus()
      }, 250)
    },
    onEnterKeyup(event) {
      if ((!this.formValid || !this.file) && event.key === 'Enter') {
        this.focusedInput = (this.focusedInput + 1) % 2
        this.focusOnInput(this.focusedInput)
      }
      if (this.formValid && event.key === 'Enter' && this.file) {
        this.onSubmit()
      }
    },
    onFilePicked(event: any): void {
      this.file = event.target.files[0]
    },
    onCancel(): void {
      this.$emit('cancel')
    },
    onSubmit(): void {
      this.$emit('onSubmit', {
        name: this.layerName,
        offline: true,
        attribution: this.mapAttribution,
        fallback: this.isFallback,
        max_zoom: this.maxZoom,
        file: this.file
      })
    },
    handleFileUpload(): void {
      this.isSelecting = true
      window.addEventListener(
        'focus',
        () => {
          this.isSelecting = false
        },
        { once: true }
      )
      this.$refs.mapFileInput.click()
    },
    dismiss(v): void {
      if (!v) {
        this.$store.commit('maps/SET_ERROR', null)
        this.$store.commit('maps/SET_STATUS', null)
      }
    }
  },
  watch: {
    active: {
      handler: function(val) {
        if (val) {
          this.focusedInput = 0
          this.focusOnInput(this.focusedInput)
        } else {
          this.$refs['offline-form'].resetValidation()
        }
      }
    }
  }
})
